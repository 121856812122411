


































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import {namespace} from 'vuex-class';

const UserStore = namespace('user');
/**
 * SideCard wrapper for the LocationManageContentComponent
 */
@Component({
  components: {
    UserManageContentComponent: () => import(
        /* webpackChunkName: "timeSchedulePickerComponent" */
        '@/components/user/UserManageContent.component.vue'),
    SideCardComponent: () => import(
        '@/components/shared/SideCard.component.vue'),
  },
})
export default class UserManageComponent extends Vue {

  @VModel({default: false})
  public showComponent!: boolean;

  @Prop({default: () => new User()})
  public user!: User;

  @Prop({default: () => true})
  public gotoUsersOverview!: boolean;

  @Prop({default: () => true})
  public isEditable!: boolean;

  public checkChanges: boolean = false;

  public showDiscardChangesDialog: boolean = false;

  public onCloseSideCardClick() {
    this.checkChanges = true;
  }

  public closeSideCard() {
    this.showComponent = false;
    this.checkChanges = false;
  }

  public onExitModal(user: User) {
    this.showComponent = false;
    this.$emit('exitModal');
  }

  public onDiscardClick() {
    this.showDiscardChangesDialog = false;
    this.closeSideCard();
  }

  public onBackClick() {
    this.showDiscardChangesDialog = false;
    this.checkChanges = false;
  }
}
